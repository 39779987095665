import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { badges, colors } from "../../styles/variables";
import textStyles from "../../styles/textStyle";
import rem from "../../styles/styleHelpers";

type BadgeSize = "large" | "medium" | "small";
export interface IBadgeStyledProps {
  className?: string;
  size?: BadgeSize;
  label?: string | number;
}

const large = css`
  line-height: ${rem(24)};
  min-width: ${rem(24)};
  height: ${rem(24)};
  font-size: ${rem(13)};
`;

const medium = css`
  line-height: ${rem(16)};
  min-width: ${rem(16)};
  height: ${rem(16)};
  font-size: ${rem(9.6)};
`;

const small = css`
  line-height: ${rem(8)};
  min-width: ${rem(8)};
  height: ${rem(8)};
  font-size: ${rem(6.4)};
`;

const stylesBySize = {
  large,
  medium,
  small,
};

export const BadgeStyled = styled.span.attrs(() => ({
  "data-testid": "badge",
}))<IBadgeStyledProps>`
  ${({ size }) => size && stylesBySize[size]}
  display: inline-block;
  background: ${badges.badgeBg};
  border-radius: 40%;
  color: ${badges.badgeColor};
  text-align: center;
  font-weight: ${textStyles.mediumFontWeight};
  vertical-align: text-bottom;
  letter-spacing: 0;
  ${rtl`margin-left: 0.3rem`};
  ${rtl`margin-right: 0.3rem`};
  .error {
    background: ${colors.red700};
  }
`;
