import React, {
  ReactNode,
  ReactElement,
  useState,
  useRef,
  useEffect,
  MutableRefObject,
} from "react";
import { injectIntl } from "react-intl";
import * as Styles from "./KeywordPopup.styles";
import {
  Wrapper,
  KeywordLink,
  Close,
  Title,
  Popup,
  Content,
} from "./KeywordPopup.styles";

type KeywordPopupProps = {
  readonly keyword: string;
  readonly popupTitle: string;
  readonly popupLocation: "top" | "bottom";
  readonly children: ReactNode;
  readonly intl: any;
};

const focus = (ref: MutableRefObject<HTMLButtonElement | undefined>) => {
  ref.current?.focus();
  ref.current?.setAttribute("data-focus-visible_added", "");
};

const KeywordPopup = ({
  keyword,
  popupTitle,
  popupLocation = "top",
  children,
  intl,
}: KeywordPopupProps): ReactElement => {
  const linkRef = useRef<HTMLButtonElement>();
  const closeRef = useRef<HTMLButtonElement>();
  const wasVisible = useRef<boolean>(false);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const title = popupTitle ? popupTitle : keyword;
  const toggle = () => setPopupVisible(!popupVisible);

  useEffect(() => {
    if (popupVisible) {
      focus(closeRef);
    } else if (wasVisible.current) {
      focus(linkRef);
    }
    wasVisible.current = popupVisible;
  }, [popupVisible]);

  return (
    <>
      <Wrapper>
        <Popup
          popupLocationIsBottom={popupLocation === "bottom"}
          popupVisible={popupVisible}
        >
          {title && <Title>{title}</Title>}
          <Close
            ref={_ref => (closeRef.current = _ref ?? undefined)}
            onClick={toggle}
            aria-label={intl.formatMessage({ id: "modal.close" })}
          >
            &#10005;
          </Close>
          <Content>{children}</Content>
        </Popup>
      </Wrapper>
      <KeywordLink
        ref={_ref => (linkRef.current = _ref ?? undefined)}
        onClick={toggle}
      >
        {keyword}
      </KeywordLink>
    </>
  );
};

export default injectIntl(KeywordPopup);
export { Styles };
