import * as styleVariables from "./styles/variables";
import * as styleHelpers from "./styles/styleHelpers";
import * as mediaQueryMixins from "./styles/mixins/mediaQueryMixins";
export { styleVariables, styleHelpers, mediaQueryMixins };

import Accordion, * as AccordionExtras from "./components/Accordion";
import Avatar, * as AvatarExtras from "./components/Avatar";
import Badge, * as BadgeExtras from "./components/Badge";
import Button, * as ButtonExtras from "./components/Button";
import Card, * as CardExtras from "./components/Card";
import CheckBox, * as CheckBoxExtras from "./components/CheckBox";
import ChoiceList, * as ChoiceListExtras from "./components/ChoiceList";
import ColumnLayout, * as ColumnLayoutExtras from "./components/ColumnLayout";
import CustomModal, * as CustomModalExtras from "./components/CustomModal";
import Flash, * as FlashExtras from "./components/Flash";
import Heading, * as HeadingExtras from "./components/Heading";
import Icon, * as IconExtras from "./components/Icon";
import KeywordPopup, * as KeywordPopupExtras from "./components/KeywordPopup";
import Spacing, * as SpacingExtras from "./components/Spacing";
import Spinner, * as SpinnerExtras from "./components/Spinner";

export {
  Accordion,
  Avatar,
  Badge,
  Button,
  Card,
  CheckBox,
  ChoiceList,
  ColumnLayout,
  CustomModal,
  Flash,
  Heading,
  Icon,
  KeywordPopup,
  Spacing,
  Spinner,
  AccordionExtras,
  AvatarExtras,
  BadgeExtras,
  ButtonExtras,
  CardExtras,
  CheckBoxExtras,
  ChoiceListExtras,
  ColumnLayoutExtras,
  CustomModalExtras,
  FlashExtras,
  HeadingExtras,
  IconExtras,
  KeywordPopupExtras,
  SpacingExtras,
  SpinnerExtras,
};

export const SIZE_SCALE = Object.freeze({
  none: "0",
  xxsmall: "xxs",
  xsmall: "xs",
  small: "s",
  medium: "m",
  large: "l",
  xlarge: "xl",
});
