import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  zIndexes,
  colors,
  mediaQueries,
  typography,
  scaffolding,
} from "../../styles/variables";
import { mediaMax, media } from "../../styles/mixins/mediaQueryMixins";
import { fadeIn } from "../../styles/animations";
import rem from "../../styles/styleHelpers";

const { medium, small } = mediaQueries;
const { gridBorderColor } = scaffolding;

type PopupProps = {
  popupLocationIsBottom: boolean;
  popupVisible: boolean;
};

export const Wrapper = styled.span`
  position: absolute;
  z-index: ${zIndexes.modal};
  height: 20px;
  pointer-events: none;
  ${mediaMax(medium + small * 0.44 - 1)} {
    width: 86vw;
    position: absolute;
    ${rtl`left: 20px;`};
  }
`;

export const KeywordLink = styled.button.attrs(() => ({
  "data-testid": "keywordpopup-link",
}))`
  all: unset;
  cursor: pointer;
  font-weight: bold;
  color: ${colors.blue500};
  border-bottom: 2px dashed ${colors.blue500};

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
  }
`;

export const Close = styled.button.attrs(() => ({
  "data-testid": "keywordpopup-close",
}))`
  all: unset;
  position: absolute;
  top: 16px;
  ${rtl`right: 16px;`};
  color: ${colors.blue500};
  cursor: pointer;
  z-index: ${zIndexes.modalClose};
  &:hover {
    color: ${colors.gray950};
  }

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
  }
`;

export const Title = styled.span`
  font-family: ${typography.fontFamilyHeadings};
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  color: ${colors.gray950};
`;

const PopupTop = css`
  bottom: 130%;
  &::after {
    content: "";
    font-size: 0%;
    position: absolute;
    top: 100%;
    ${rtl`
      left: 50%;
      padding-right: 0px;
      padding-left: 10px;
    `};
    padding-bottom: 10px;
    margin-top: -5px;
    border-width: 1px;
    border-style: solid;
    background-color: ${colors.gray50};
    border-color: transparent ${gridBorderColor} ${gridBorderColor} transparent;
    transform: rotate(45deg);
    ${mediaMax(medium + small * 0.44 - 1)} {
      display: none;
    }
  }
`;

const PopupBottom = css`
  top: 130%;
  &::before {
    content: "";
    font-size: 0%;
    position: absolute;
    top: 0%;
    ${rtl`
      left: 50%;
      padding-right: 0px;
      padding-left: 10px;
    `};
    padding-bottom: 0px;
    padding-bottom: 10px;
    margin-top: -6px;
    border-width: 1px;
    border-style: solid;
    background-color: ${colors.gray50};
    border-color: transparent ${gridBorderColor} ${gridBorderColor} transparent;
    transform: rotate(-135deg);
    ${mediaMax(medium + small * 0.44 - 1)} {
      display: none;
    }
  }
`;

export const Popup = styled.div.attrs(() => ({
  "data-testid": "keywordpopup",
}))<PopupProps>`
  ${rtl`
    left: 50%;
    transform: translateX(-50%);
  `};
  position: absolute;
  border: ${gridBorderColor} 1px solid;
  max-width: ${small * 0.57}px;
  width: 86vw;
  margin: 0 auto;
  z-index: ${zIndexes.tooltip};
  padding: 16px;
  background: ${colors.white};
  animation: ${fadeIn} 0.2s;
  white-space: pre-wrap;
  background: ${colors.gray50};
  border: 1px solid ${gridBorderColor};
  border-radius: 6px;
  box-sizing: border-box;
  height: auto;
  pointer-events: auto;
  ${mediaMax((2 * small) / 3)} {
    min-width: ${small / 3}px;
  }
  ${media(medium, medium + small * 0.44)} {
    ${rtl`
      transform: translateX(-50%);
      left: 40%;
    `}
    position: absolute;
    height: auto;
  }
  ${mediaMax(medium)} {
    ${rtl`transform: translateX(-50%);`};
    position: absolute;
    height: auto;
  }
  ${({ popupLocationIsBottom }) =>
    popupLocationIsBottom ? PopupBottom : PopupTop}
  ${({ popupVisible }) => !popupVisible && "display: none;"}
`;

export const Content = styled.span.attrs(() => ({
  "data-testid": "keywordpopup-content",
}))`
  font-family: ${typography.fontFamilyHeadings};
  font-style: normal;
  font-weight: normal;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  color: ${colors.gray950};
  overflow-wrap: break-word;
  ${rtl`align-items: left;`};
`;
