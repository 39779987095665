import styled, { css } from "styled-components";
import rem from "../../styles/styleHelpers";
import {
  colors,
  spacing,
  scaffolding,
  typography,
} from "../../styles/variables";

type ChoiceListItemProps = {
  active: boolean;
};

const activeStyles = css`
  color: ${colors.white};
  background-color: ${colors.blue500};
`;

export const ChoiceListItemStyled = styled.li<ChoiceListItemProps>`
  text-transform: none;
  font-size: ${typography.fontSizeSmall};
  letter-spacing: ${rem(1)};
  padding: 13px ${spacing.xs}px;
  font-weight: ${scaffolding.bold};
  color: ${colors.gray700};
  cursor: pointer;

  &:hover {
    ${activeStyles}
  }

  & > p {
    margin: 0;
  }
  ${({ active }) => active && activeStyles}
`;
