// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { padStart, debounce, isNil, groupBy, isPlainObject } from "lodash";
import moment from "moment";
import { SiteSettings } from "./holvikaari";

// Loosely simulates Bluebird finally.
export const lastly = (promise_arg, after_fn) =>
  promise_arg
    .then(val => [undefined, val])
    .catch(err => [err, undefined])
    .then(([err, val]) => after_fn(err, val));

export const lock = (promise_arg, lock_fn) => {
  lock_fn(true);
  lastly(promise_arg, (err, val) => lock_fn(false, err, val));
};

export const padDate = num => padStart(num?.toString(), 2, "0");
// convert from date string to an object so we can show fields for day/month/year
export const birthdate = (date: string | number | Date) => {
  const bd = new Date(date);
  return {
    bd_m: bd.getMonth() + 1,
    bd_d: bd.getDate(),
    bd_y: bd.getFullYear(),
  };
};
export const defaults = (val, def) => (isNil(val) ? def : val);

export const isIE = /(MSIE|Trident)/.test(window.navigator.userAgent);

// Used to check whether or not the site has conversations enabled at all on any domain
// Needed for when the user is not yet initialized from the state during loading
export const areConversationsEnabled = () => {
  if (isPlainObject(SiteSettings.conversations)) {
    return Object.values(SiteSettings.conversations).includes(true);
  } else {
    return SiteSettings.conversations;
  }
};

// https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11/32889608#32889608
export const elDispatchEvent = (el, ev) => {
  let event;
  if (isIE) {
    event = document.createEvent("HTMLEvents");
    event.initEvent(ev, true, true);
  } else {
    event = new Event(ev);
  }
  return el.dispatchEvent(event);
};

// Removes the locale part of the url
// From: /en-US/path/to/somewhere
// to: /path/to/somewhere
export const cleanUrlLocalePrefix = path => {
  return "/" + path.split("/").slice(2).join("/");
};

// Debounces invocations to fn, that returns a promise
// Calls cb node-style when promise returned by fn finishes
// Discards old promises when fn is called again
export const debouncedAsyncExec = (fn, cb, ms = 400) => {
  let lastExecTime = 0;
  return debounce((...args) => {
    const execTime = (lastExecTime = Date.now());
    fn(...args)
      .then(d => execTime === lastExecTime && cb(undefined, d))
      .catch(e => execTime === lastExecTime && cb(e));
  }, ms);
};

export const toCamelCase = input =>
  input
    .split("")
    .reduce((acc, currVal, currIdx) => {
      if (currVal === currVal.toUpperCase() && currIdx > 0) acc.push("_");
      acc.push(currVal.toLowerCase());
      return acc;
    }, [])
    .join("");

export const averageTime = (...moments) =>
  moment((1000 * moments.reduce((m, i) => m + i.unix(), 0)) / moments.length);

export const isToday = date => moment(date).isSame(moment(), "day");

export const splitBy = (collection, predicate) => {
  const { true: truthy, false: falsy } = groupBy(collection, predicate);
  const empty = collection.constructor;
  return [truthy || empty(), falsy || empty()];
};

export const tap = fn => arg => {
  fn(arg);
  return arg;
};

export class Deferred {
  promise: Promise<any>;
  reject: (value: any) => void;
  resolve: (value: any) => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
