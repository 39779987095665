import { ReactNode } from "react";
import styled, { css } from "styled-components";
import rem from "../../styles/styleHelpers";
import { colors } from "../../styles/variables";

type Size = "large" | "xl" | "xxl";

export const avatarSize = (size?: Size) => {
  switch (size) {
    case "large":
      return rem(48);
    case "xl":
      return rem(96);
    case "xxl":
      return rem(200);
    default:
      return rem(32);
  }
};

type Avatar = string;

export type User =
  | {
      avatar?: Avatar;
      type: string;
    }
  | undefined;
export interface IAvatarStyledProps {
  className?: string;
  /** Size of the component, small or large */
  size?: Size;
  /** Whether to hide the actual image of the person, e.g. because of data privacy reasons */
  hide?: boolean;
  children?: ReactNode;
  /** User shown on the component */
  user?: User;
}

interface IWithoutAvatarStyledProps {
  className?: string;
  size?: Size;
}

const avatarBaseStyle = css`
  display: inline-block;
  width: ${rem(32)};
  height: ${rem(32)};
  line-height: ${rem(32)};
  background-size: cover;
  border-radius: 50%;
  vertical-align: middle;
`;

const xxl = css`
  /* stylelint-disable plugin/unit-use-rem-function */
  width: 200px;
  height: 200px;
  line-height: 200px;
  /* stylelint-enable plugin/unit-use-rem-function */
  border-radius: 6px;
  color: ${colors.white};

  &::after {
    display: none;
  }
`;

const xl = css`
  /* stylelint-disable plugin/unit-use-rem-function */
  width: 96px;
  height: 96px;
  line-height: 96px;
  /* stylelint-enable plugin/unit-use-rem-function */
  border-radius: 0;
`;

const large = css`
  width: ${rem(48)};
  height: ${rem(48)};
  line-height: ${rem(48)};
`;

const stylesBySize = {
  xxl,
  xl,
  large,
};

export const AvatarStyled = styled.div.attrs(() => ({
  "data-testid": "avatar",
}))<IAvatarStyledProps>`
  ${avatarBaseStyle}
  ${({ size }) => size && stylesBySize[size]}
`;

export const WithoutAvatarStyled = styled.span.attrs(() => ({
  "data-testid": "without-avatar",
}))<IWithoutAvatarStyledProps>`
  background-color: rgba(100, 0, 0, ${colors.gray25Alpha});
  ${avatarBaseStyle}
  ${({ size }) => size && stylesBySize[size]}
`;
