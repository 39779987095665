import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

export const fadeInUp = keyframes`
  0% { opacity: 0; transform: translateY(50%); }
  100% { opacity: 1; }
`;
