import React from "react";
import { Motion, spring, presets, PlainStyle } from "react-motion";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import ReactResizeDetector from "react-resize-detector/build/withPolyfill";
// TODO
import Icon from "../Icon";
import * as Styles from "./Flash.styles";
import {
  FlashStyled,
  Content,
  CloseButton,
  ReadMoreButton,
  ReadMoreContainer,
  InnerContent,
  FlashIcon,
} from "./Flash.styles";
import { Flash as FlashModel, FlashType } from "./Flash.types";
import { uniqueId } from "lodash";

export type FlashProps = {
  children?: string | React.ReactNode | any[];
  /** Function to be executed when closing the component */
  onClose?: () => void;
  /** Type of the component */
  type: FlashType;
  /** Whether the component is hidden when changing the page */
  leaving?: boolean;
  /** Should the flash be collapsed if the content is too long */
  collapsible?: boolean;
  iconSize: "medium" | "large";
  intl: IntlShape;
};

/**
  The Flash component is used to give feedback about major actions to users or to inform users
  of persistent conditions requiring their attention.
*/
class Flash extends React.Component<FlashProps, any> {
  static defaultProps = {
    type: "info",
    leaving: false,
    collapsible: false,
  } as FlashProps;

  constructor(props: FlashProps) {
    super(props);
    this.wrapRef = React.createRef();
    this.contentRef = React.createRef();
    this.state = {
      leaving: props.leaving,
      overflowing: true,
      forceContentExpansion: !props.collapsible,
    };
  }

  componentDidMount() {
    this.updateContentHeights.bind(this)();
  }

  UNSAFE_componentWillReceiveProps(nextProps: FlashProps) {
    if (nextProps.leaving) this.animateOut();
  }

  static icons = {
    alert: "alert_circle_48px",
    info: "info_circle_48px",
    warning: "alert_circle_48px",
    success: "ok_circle_48px",
  } as const;

  private wrapRef: React.RefObject<any>;
  private contentRef: React.RefObject<any>;

  updateContentHeights() {
    const overflowing =
      this.contentRef.current.scrollHeight >
      this.contentRef.current.clientHeight;

    this.setState({
      overflowing,
      contentScrollHeight: this.contentRef.current.scrollHeight,
      contentClientHeight: this.contentRef.current.clientHeight,
    });
  }

  // The flash is animated out by decreasing its margin by its offsetHeight
  // onClose gets called once the element has finished transitioning out
  getDefaultStyle() {
    return { opacity: 1, marginTop: 0 };
  }

  getStyle() {
    return {
      marginTop: spring(this.state.height || 0, presets.stiff),
      opacity: spring(0, presets.stiff),
    };
  }

  animateOut() {
    this.setState({
      leaving: true,
      height: -this.wrapRef.current.offsetHeight,
    });
  }

  expandContent() {
    this.setState({ forceContentExpansion: true });
  }

  collapsedContent() {
    const { children, type } = this.props;
    return (
      <>
        <InnerContent ref={this.contentRef}>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.updateContentHeights.bind(this)}
          />
          {children}
        </InnerContent>
        {this.state.overflowing && (
          <ReadMoreContainer $type={type}>
            <ReadMoreButton onClick={this.expandContent.bind(this)}>
              <FormattedMessage id="articles.read_more" />
            </ReadMoreButton>
          </ReadMoreContainer>
        )}
      </>
    );
  }

  expandedContent() {
    const { children, type } = this.props;
    return (
      <>
        <Motion
          defaultStyle={{ height: this.state.contentClientHeight ?? 0 }}
          style={{
            height: spring(this.state.contentScrollHeight ?? 0),
          }}
          onRest={() => this.setState({ overflowing: false })}
        >
          {interpolatingStyle => (
            <div
              data-testid={`flash-${type}-inner-content`}
              id={`flash-${type}-inner-content`}
              ref={this.contentRef}
              style={interpolatingStyle}
            >
              {children}
            </div>
          )}
        </Motion>
      </>
    );
  }

  content(style?: PlainStyle) {
    const { type, onClose, iconSize } = this.props;
    const id = `flash-${type}-${uniqueId()}`;
    return (
      <FlashStyled style={style} $type={type} aria-labelledby={id}>
        <FlashIcon $type={type} size={iconSize} name={Flash.icons[type]} />
        <Content id={id}>
          {this.state.forceContentExpansion
            ? this.expandedContent()
            : this.collapsedContent()}
        </Content>
        {onClose && (
          <CloseButton
            $type={type}
            aria-label={this.props.intl.formatMessage({
              id: "modal.close",
            })}
            data-name={"dismiss"}
            onClick={this.animateOut.bind(this)}
          >
            <Icon name="remove" size="medium" aria-hidden="true" />
          </CloseButton>
        )}
      </FlashStyled>
    );
  }

  // Motion container rendered only when close event has been triggered
  renderContent() {
    const { onClose } = this.props;
    if (this.state.leaving)
      return (
        <Motion
          defaultStyle={this.getDefaultStyle()}
          style={this.getStyle()}
          onRest={onClose}
        >
          {this.content.bind(this)}
        </Motion>
      );
    else return this.content();
  }

  // Height is measured from the wrapper, also sets margin
  render() {
    return <div ref={this.wrapRef}>{this.renderContent()}</div>;
  }
}

export default injectIntl(Flash);
export { Styles };
export type { FlashModel, FlashType };
