import styled from "styled-components";
import rtl from "styled-components-rtl";
import { colors, badges } from "../../styles/variables";
import rem from "../../styles/styleHelpers";

export type IconSpanProps = {
  align?: string;
  badge?: string | number;
};

export const IconContainer = styled.span.attrs(({ badge }: IconSpanProps) => ({
  "data-testid": "icon-container",
  "data-badge": badge,
  "data-badge-present": !badge ? "false" : "true",
}))<IconSpanProps>`
  ${({ align }) => align === "left" && rtl`margin-right: 0.5em;`}
  ${({ align }) => align === "right" && rtl`margin-left: 0.5em;`}

  ${({ badge }) =>
    badge &&
    rtl`
      position: relative;

      &::after {
        display: block;
        position: absolute;
        content: "${badge}";
        top: -8px;
        right: -7px;
        color: ${colors.white};
        font-size: ${rem(10)};
        font-weight: 400;
        line-height: ${rem(10)};
        padding: 2px 3px;
        border-radius: 50%;
        border: ${colors.white} 2px solid;
        background: ${badges.badgeBg};
        height: 18px;
        min-width: 18px;
        text-align: center;
      }
  `}

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: -2px;
  }
`;
