import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  mediaQueries,
  tagsInConversation,
  zIndexes,
} from "../../styles/variables";
import { fadeIn } from "../../styles/animations";
import { media, mediaMax } from "../../styles/mixins/mediaQueryMixins";
import { rgba } from "polished";

export const CustomModalStyled = styled.div.attrs(() => ({
  "data-testid": "styled-modal",
}))`
  position: fixed;
  z-index: ${zIndexes.overlay};
  ${rtl`left: 0`};
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${rgba(
    tagsInConversation.overlayColor,
    tagsInConversation.overlayOpacity,
  )};
  animation: ${fadeIn} 0.2s;
`;

export const CustomModalHeaderStyled = styled.div.attrs(() => ({
  "data-testid": "custom-modal-header",
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: ${colors.blue50};
  padding: 20px 10px;
`;

export const CustomModalCloseButton = styled.button.attrs(() => ({
  "data-testid": "custom-modal-close-button",
}))`
  background-color: transparent;
  cursor: pointer;
  border: 0px;
`;

const fullScreenStyles = css`
  && {
    width: 100%;
    min-height: 100%;
    margin: 0;
  }
`;

type CustomModalContentProps = {
  noPadding: boolean;
  fullScreen: boolean;
  borderRadius: boolean;
  narrow: boolean;
};

export const CustomModalContent = styled.div.attrs(() => ({
  "data-testid": "custom-modal-content",
}))<CustomModalContentProps>`
  position: relative;
  background-color: ${colors.white};
  margin: 10% auto;
  padding: 24px;
  width: ${({ narrow }) => (narrow ? "25%" : "60%")};

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: 25px;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  ${({ fullScreen }) => fullScreen && fullScreenStyles}

  ${mediaMax(mediaQueries.large)} {
    width: 60%;
  }

  ${media(mediaQueries.small + 1, mediaQueries.medium)} {
    width: 75%;
  }

  ${mediaMax(mediaQueries.small)} {
    width: 95%;
  }
`;
