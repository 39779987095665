import React, { useState, useRef, useEffect, ReactNode } from "react";
import { injectIntl, IntlShape } from "react-intl";

import Icon from "../Icon";

import * as Styles from "./CustomModal.styles";
import {
  CustomModalCloseButton,
  CustomModalContent,
  CustomModalHeaderStyled,
  CustomModalStyled,
} from "./CustomModal.styles";

export type BaseProps = {
  children: ReactNode;
  fullScreen?: boolean;
  noPadding?: boolean;
  narrow?: boolean;
  borderRadius?: boolean;
  className?: string;
  showHeader?: boolean;
  headerText?: string | React.ReactChild;
  role?: "dialog" | "alertdialog";
  ariaDescription?: string;
  closeModal?(): void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

type Props = BaseProps & { ariaLabel?: string; intl: IntlShape };

const focusableElements =
  'button:not([disabled]), a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

function CustomModal(props: Props) {
  const [firstRender, setFirstRender] = useState(true);
  const {
    children,
    fullScreen = false,
    noPadding = false,
    borderRadius = false,
    showHeader = true,
    closeModal,
    narrow,
    role,
    ariaDescription,
    intl,
    className,
  } = props;
  const headerText = "headerText" in props ? props.headerText : undefined;
  const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
  const modalRef = useRef<HTMLDivElement>(null);

  function onModalOverlayClick(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLDivElement;
    if (typeof closeModal === "function" && target?.id === "customModal") {
      closeModal();
    }
  }

  useEffect(() => {
    const focusableModalElements =
      modalRef?.current?.querySelectorAll(focusableElements);

    const firstElement = focusableModalElements && focusableModalElements[0];

    const lastElement =
      focusableModalElements &&
      focusableModalElements[focusableModalElements.length - 1];

    if (firstRender && firstElement) {
      (firstElement as HTMLElement).focus();
      setFirstRender(false);
    }

    function keyListener(e: KeyboardEvent) {
      if (e.code === "Escape" && closeModal) {
        closeModal();
      }

      if (e.key === "Tab" && modalRef) {
        if (!e.shiftKey && document.activeElement === lastElement) {
          (firstElement as HTMLElement).focus();
          e.preventDefault();
        }

        if (e.shiftKey && document.activeElement === firstElement) {
          (lastElement as HTMLElement).focus();
          e.preventDefault();
        }
      }
    }

    document.addEventListener("keydown", keyListener);
    return () => document.removeEventListener("keydown", keyListener);
  }, [modalRef, children]);

  return (
    <CustomModalStyled
      onClick={e => {
        onModalOverlayClick(e);
        if (typeof props.onClick === "function") {
          props.onClick(e);
        }
      }}
      role={role ?? "dialog"}
      aria-label={ariaLabel}
      aria-description={ariaDescription}
      aria-modal="true"
      tabIndex={-1}
      id="customModal"
      ref={modalRef}
      className={className}
    >
      {showHeader && headerText && (
        <CustomModalHeaderStyled>
          <CustomModalCloseButton
            onClick={closeModal}
            onKeyPress={closeModal}
            tabIndex={0}
            aria-label={intl.formatMessage({ id: "modal.close" })}
          >
            <Icon name="remove" size="medium" />
          </CustomModalCloseButton>
          {headerText}
        </CustomModalHeaderStyled>
      )}

      <CustomModalContent
        borderRadius={borderRadius}
        fullScreen={fullScreen}
        noPadding={noPadding}
        narrow={!!narrow}
      >
        {children}
      </CustomModalContent>
    </CustomModalStyled>
  );
}

export default injectIntl(CustomModal);
export { Styles };
