/* eslint-disable max-lines */

import { lighten } from "polished";
import rem, {
  getSiteSpecificColors,
  getSiteSpecificPrimaryColor,
  getSiteSpecificBadgeBg,
  getSiteSpecificTaskBg,
  getSiteSpecificOkBtnColor,
  getSiteSpecificLinkColor,
  getSiteSpecificLinkHoverColor,
  getSiteSpecificFontFamilyHeadings,
  getSiteSpecificFontFamilySansSerif,
} from "../styles/styleHelpers";
import { BaseColors, Colors } from "styled-components";

import { spacing } from "./styleHelpers";
export { spacing };
export type { SpacingKey } from "./styleHelpers";

// COLORS --------------------------------------------
// Each shade corresponds roughly to the way they are used, like this:
//
// color50    BACKGROUND
// Use this in elements which require a coloured background.
// Preferably use coloured backgrounds only when they
// serve some function, i.e. not "just because".
//
// color300   LIGHT
// The light tone is for special cases, mostly data visualization
// and categorization purposes. Avoid unless you really need this.
//
// color500   DEFAULT
// Use the default tone whenever possible, unless
// you have a good reason to not use it :)
//
// color700   TEXT
// Use this whenever you need a text of some colour,
// since it's more readable than the lighter tones.
//
//----------------------------------------------------

export const sitelessBaseColors: BaseColors = {
  // Grayscale colors
  black: "#000",
  white: "#fff",
  blue50: "#f2f2fc",
  blue100: "#dfdff6",
  blue200: "#acade5",
  blue300: "#787bd4",
  blue400: "#5f62cc",
  blue500: "#4549c3",
  blue700: "#393b8e",
  blue900: "#2c2d58",
  // From 2020 color document: https://www.dropbox.com/s/r89l9ju4wqfyika/Colors_Kaiku.pdf
  gray50: "#f6f6f6",
  gray300: "#e7e7e7",
  gray500: "#d7d7d7",
  gray700: "#757575",
  gray800: "#555",
  gray900: "#373737",
  gray950: "#1f1f1f",

  gray25Alpha: 0.03, // !default; on white background black with 0.03 alpha is same as gray25
  gray400: "#999",

  // Kaiku brand
  yellow50: "#ffefcc",
  yellow100: "#ffe9a3",
  yellow200: "#ffde52",
  yellow300: "#ffd200",
  yellow500: "#fac800",
  // yellow700 was removed because it is too close to brown, and no
  // WCAG-compatible yellows that do not look brown could be found
  yellow850: "#b29111",
  yellow900: "#655922",

  red50: "#ffeeee",
  red100: "#fcd8d5",
  red200: "#f6ada2",
  red300: "#f0826f",
  red500: "#e53c3c",
  red700: "#cd2020",
  red900: "#7c1a1a",

  green50: "#edf8e6",
  green300: "#9de65a",
  green500: "#8ad446",
  green700: "#48880d",
  green800: "#2f5908",
  green900: "#29440f",

  jade300: "#b8efcc",
  brick300: "#cb8579",

  tableRowAlternate: "#f7f7f7",

  transparent: "rgba(0, 0, 0, 0)",
};

export const baseColors = getSiteSpecificColors(sitelessBaseColors);
export const primaryColor = getSiteSpecificPrimaryColor(
  sitelessBaseColors.blue500,
);
export const linkColor = getSiteSpecificLinkColor(primaryColor);
export const linkHoverColor = getSiteSpecificLinkHoverColor(linkColor);

export const colors: Colors = {
  ...baseColors,
  primaryColor,
  linkColor,
  linkHoverColor,
};

export const text = {
  size: {
    small: rem(14),
    medium: rem(16),
    large: rem(18),
  },
  lineHeight: {
    small: rem(22),
    medium: rem(24),
    large: rem(28),
  },
  fontWeight: {
    regular: 400,
    medium: 500,
  },
};

export const bodyText = {
  small: `
  font-size: ${text.size.small};
  line-height: ${text.lineHeight.small};
`,
  medium: `
  font-size: ${text.size.medium};
  line-height: ${text.lineHeight.medium};
`,
  large: `
  font-size: ${text.size.large};
  line-height: ${text.lineHeight.large};
`,
};

// Scaffolding
export const scaffolding = {
  bodyBg: colors.white,
  textColor: colors.gray950,
  headingColor: "inherit",
  pagePadding: "1.5rem",
  pagePaddingMobile: "1rem",
  bold: 500,
  fontWeightNormal: 200,
  sidebarBackground: colors.white,
  gridBorderColor: colors.gray300,
  spinnerColor: colors.blue100,
  fontFamilyBase: 'Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSizeBase: rem(16),
};
// Typography
export const typography = {
  fontFamilyHeadings: getSiteSpecificFontFamilyHeadings(
    'Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ),
  fontFamilySansSerif: getSiteSpecificFontFamilySansSerif(
    'Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ),
  get fontFamilyBase() {
    return typography.fontFamilySansSerif;
  },
  fontSizeBase: rem(16),
  fontSizeMedium: rem(14),
  fontSizeSmall: rem(13),
  fontSizeXsmall: rem(11),
  baseLineHeight: 1.5,
  mainTextColor: colors.gray950,
};

// Symptom grades
export const symptomGrades = {
  emptyCell: colors.gray50,
  severity0: colors.jade300,
  severity1: colors.green300,
  severity2: colors.yellow300,
  severity3: colors.red300,
  severity4: colors.brick300,
};

export const getSymptomGradeColor = (key: number) => {
  switch (key) {
    case 0:
      return symptomGrades.severity0;
    case 1:
      return symptomGrades.severity1;
    case 2:
      return symptomGrades.severity2;
    case 3:
      return symptomGrades.severity3;
    case 4:
      return symptomGrades.severity4;
    default:
      break;
  }
};

// graph series coloring
export const graph = {
  purple: "#9768d1",
  pink: "#f46d8d",
  brown: "#99583d",
};

// treatment program label colors
export const treatmentProgramLabels = {
  background: {
    green: "#c9ebd5",
    grey: "#ededed",
    blue: "#d5e8f9",
    yellow: "#f8eaba",
    brown: "#ebdfd3",
    orange: "#ffdfaf",
    pink: "#fde4e3",
    purple: "#ece0ff",
  },
  text: {
    green: "#3d5d36",
    grey: "#505050",
    blue: "#415363",
    yellow: "#6c590f",
    brown: "#5b4d48",
    orange: "#904523",
    pink: "#9b3e33",
    purple: "#604a9a",
  },
};

export const graphLine = [
  colors.blue500,
  colors.green500,
  colors.yellow500,
  colors.red500,
  graph.purple,
  graph.pink,
  graph.brown,
  colors.red300,
  colors.green300,
  colors.blue100,
  treatmentProgramLabels.background.orange,
  treatmentProgramLabels.background.yellow,
];

export const graphArea = [
  colors.blue50,
  colors.green50,
  colors.yellow50,
  colors.red50,
  lighten(0.1, graph.purple),
  lighten(0.1, graph.pink),
  lighten(0.1, graph.brown),
];

export const iconSize = 24;

// Input form with a single answer
export const singleValueText = primaryColor;

// Navigation
export const navigationDimensions = {
  logo: {
    containerHeight: 120,
    height: 50,
    width: 161,
  },
  sideBarWidth: 264,
  sideBarWidthMinimized: 120,
};

export const navigation = {
  activeColor: primaryColor,
  linkColor: colors.blue700,
  mobileNavHeight: 50,
  sidebar: {
    width: navigationDimensions.sideBarWidth,
    widthMinimized: 120,
    profileHeight: 72,
    linkColor: colors.gray900,
    linkHoverColor: colors.gray950,
    profileColor: colors.gray950,
    itemColor: colors.gray700,
  },
  logo: {
    containerHeight: 120,
    height: 50,
    width: 161,
    paddingVertical:
      (navigationDimensions.logo.containerHeight -
        navigationDimensions.logo.height) /
      2,
    paddingHorizontal:
      (navigationDimensions.sideBarWidth - navigationDimensions.logo.width) / 2,
  },
};

export const headerHeight =
  navigationDimensions.logo.height + 2 * navigation.logo.paddingVertical;

// Labels
export const label = {
  background: colors.blue500,
  borderRadius: 4,
  largeBorderRadius: 6,
  color: colors.white,
};

// Panels
export const panel = {
  border: scaffolding.gridBorderColor,
  borderRadius: 0,
  padding: scaffolding.pagePadding,
};

// Cards
export const card = {
  border: colors.transparent,
  borderRadius: 0,
  padding: spacing.s,
  paddingSmall: spacing.xs,
};

// Tabs
export const tabs = {
  activeBorderColor: primaryColor,
  activeTextColor: colors.gray950,
  get pageNavActiveBorder() {
    return tabs.activeBorderColor;
  },
};

// Flash messages
export const flash = {
  info: {
    background: colors.blue50,
    icon: colors.blue700,
    text: colors.blue700,
  },
  success: {
    background: colors.blue50,
    icon: colors.blue700,
    text: colors.blue700,
  },
  alert: {
    background: colors.red50,
    icon: colors.red700,
    text: colors.red700,
  },
  warning: {
    background: colors.yellow50,
    icon: colors.yellow900,
    text: colors.yellow900,
  },
};

// Tooltips
export const tooltip = {
  borderRadius: 4,
  background: colors.gray950,
  color: colors.gray50,
};

export const task = {
  background: getSiteSpecificTaskBg(colors.blue50),
  get backgroundHover() {
    return lighten(0.03, task.background);
  },
  nameText: linkColor,
  nameTextHover: lighten(0.05, linkColor),
  borderRadius: 0,
  descriptionText: scaffolding.textColor,
};

// Forms
export const forms = {
  borderColor: colors.gray500,
  borderColorActive: primaryColor,
  background: colors.white,
  backgroundDisabled: colors.gray50,
  borderRadius: 4,
  placeholderColor: colors.gray400,
  textColor: colors.gray950,
  addonBackground: colors.transparent,
};

// Buttons
export const buttons = {
  borderRadius: 6,
  hover: colors.gray950,
  attachmentText: linkColor,
  primary: {
    text: scaffolding.textColor,
    background: colors.yellow300,
    hoverBackground: lighten(0.1, colors.yellow300),
  },
  secondary: {
    background: colors.blue500,
    text: colors.white,
    border: colors.blue500,
    hoverBackground: colors.black,
    hoverBorder: colors.black,
  },
  ghost: {
    background: colors.transparent,
    text: colors.blue500,
    hoverBackground: colors.white,
    hoverBorder: colors.white,
    hoverColor: colors.black,
  },
  accent: {
    background: colors.transparent,
    text: primaryColor,
    border: primaryColor,
    hoverBackground: colors.transparent,
    hoverBorder: colors.gray950,
  },
  danger: {
    background: colors.red700,
    text: colors.white,
    border: colors.red700,
    hoverBackground: colors.red900,
    hoverBorder: colors.red900,
  },
};

// Slider
const SLIDER_SIZE = 80;
const SLIDER_LINE_SIZE = 6;

export const slider = {
  handleColor: primaryColor,
  size: SLIDER_SIZE,
  lineSize: 6,
  gradientBgSize: (SLIDER_SIZE - SLIDER_LINE_SIZE) / 2,
};

// Radio & checkbox
export const radio = {
  activeColor: colors.black,
  hoverColor: colors.gray300,
  borderColor: colors.black,
};

export const choiceActiveBg = colors.yellow50;

// menu options
export const menuOptions = {
  zindex: 2000,
  maxHeight: 200,
  optionColor: lighten(0.2, forms.textColor),
  optionBg: forms.background,
  optionFocusedColor: forms.textColor,
  optionFocusedBg: lighten(0.03, colors.blue50),
  optionSelectedColor: forms.textColor,
  optionSelectedBg: lighten(0.03, colors.blue50),
  optionDisabledColor: lighten(0.6, forms.textColor),
  noResultsColor: lighten(0.4, forms.textColor),
};

// date picker
export const datePicker = {
  selectedDayBg: colors.blue500,
  selectedDayColor: colors.white,
  weekdayColor: scaffolding.textColor,
  todayColor: colors.red700,
  disabledDayBg: colors.gray50,
  disabledDayColor: colors.gray400,
};

export const zIndexes = {
  low: 100,
  inputAddon: 200,
  topBarOverlay: 200,
  headerOverlap: 200,
  topBar: 300,
  userList: 300,
  flash: 1500,
  overlay: 1600,
  modal: 1700,
  modalClose: 1800,
  tooltip: 1900,
};

// Tags in conversation
export const tagsInConversation = {
  okBtnColor: getSiteSpecificOkBtnColor(colors.green500),
  overlayOpacity: 0.6,
  overlayColor: colors.black,
  mobileLinkActive: colors.gray950,
  mobileLinkActiveBorder: colors.blue500,
  overlayColorRBG: "rgb(0, 0, 0, 0.6)",
};

export const loginPage = {
  letterSpacing: 1,
  button: {
    borderRadius: 3,
    padding: "13px 16px",
  },
  fontSize: {
    large: rem(40),
    medium: rem(20),
    mobileHeading: rem(30),
  },
  lineHeight: {
    large: rem(46),
    medium: rem(30),
    mobileHeading: rem(40),
    small: rem(20),
  },
};

// Media query breakpoints
export const mediaQueries = {
  xsmall: 420,
  small: 640,
  medium: 1024,
  large: 1400,
  smallSpacingBreakpointWidth: 991,
};

// Article page
export const articleFullWidth = 960;
export const articleWidth = mediaQueries.small;
export const pagePaddingArticle = `calc((${articleFullWidth}px - ${articleWidth}px) / 2 + ${scaffolding.pagePadding})`;

// Monitoring and care team
export const monitoringProgramBorderRadius = 8;

// Conversations
export const conversations = {
  conversationAvatarAreaWidth: 60,
  conversationEventBg: colors.blue50,
  conversationNewMessageBg: colors.gray50,
  participantListWidth: 270,
  participantListWidthMinimized: 70,
  conversationInputHeight: 50,
  conversationInputBorderColor: colors.gray400,

  unreadMessagesText: primaryColor,
  unreadMessagesLine: primaryColor,

  topbarHeight: rem(40),
  topbarBg: colors.white,
  topbarLinkColor: colors.black,
  topbarBorder: scaffolding.gridBorderColor,
  topbarText: scaffolding.textColor,
};

export const input = {
  background: colors.white,
  borderRadius: 4,
  borderColor: colors.gray500,
  borderColorActive: primaryColor,
};

// Notification badge
export const badges = {
  badgeBg: getSiteSpecificBadgeBg(colors.red300),
  badgeColor: colors.gray950,
};
