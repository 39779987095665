import PropTypes from "prop-types";
import React from "react";
import * as Styles from "./Badge.styles";
import { BadgeStyled, IBadgeStyledProps } from "./Badge.styles";

/**
  Use a badge to represent the number of interesting items the user should pay attention to, such as the amount of
  unread messages or the amount of tasks in progress.
*/
const BadgeWrapper = ({ label, size, ...props }: IBadgeStyledProps) => (
  <BadgeStyled size={size} {...props}>
    {label}
  </BadgeStyled>
);

export const Badge = ({ children, ...props }: any) => (
  <BadgeWrapper {...props}>{children}</BadgeWrapper>
);

BadgeWrapper.propTypes = {
  /** Label to be shown */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Size of the component */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  className: PropTypes.string,
  children: PropTypes.node,
};

BadgeWrapper.defaultProps = {
  size: "large",
};

export default Badge;
export { Styles };
