import React, { useRef } from "react";
import { IconContainer } from "./Icon.styles";
import SvgImage, {
  AssetName,
  RawSvgImage,
  RawSvgImageProps,
  iconId,
} from "./SvgImage";
import { svgSize } from "./SvgImage.styles";

export const icons = {
  // 16px icon assets
  absences_16px: require("../../assets/icons/16px/icon_absences_16px.svg"),
  access_time_16px: require("../../assets/icons/16px/icon_access_time_16px.svg"),
  add_circle_16px: require("../../assets/icons/16px/icon_add_circle_16px.svg"),
  analytics_circle_16px: require("../../assets/icons/16px/icon_analytics_circle_16px.svg"),
  analytics_square_16px: require("../../assets/icons/16px/icon_analytics_square_16px.svg"),
  arrow_down_16px: require("../../assets/icons/16px/icon_arrow_down_16px.svg"),
  arrow_left_16px: require("../../assets/icons/16px/icon_arrow_left_16px.svg"),
  arrow_right_16px: require("../../assets/icons/16px/icon_arrow_right_16px.svg"),
  arrow_up_16px: require("../../assets/icons/16px/icon_arrow_up_16px.svg"),
  arrowhead_down_16px: require("../../assets/icons/16px/icon_arrowhead_down_16px.svg"),
  arrowhead_left_16px: require("../../assets/icons/16px/icon_arrowhead_left_16px.svg"),
  arrowhead_right_16px: require("../../assets/icons/16px/icon_arrowhead_right_16px.svg"),
  arrowhead_up_16px: require("../../assets/icons/16px/icon_arrowhead_up_16px.svg"),
  arrow_round_left_16px: require("../../assets/icons/16px/arrow_round_left_16px.svg"),
  arrow_round_right_16px: require("../../assets/icons/16px/arrow_round_right_16px.svg"),
  assignment_16px: require("../../assets/icons/16px/icon_assignment_16px.svg"),
  attachment_16px: require("../../assets/icons/16px/icon_attachment_16px.svg"),
  bar_chart_16px: require("../../assets/icons/16px/icon_bar_chart_16px.svg"),
  bell_16px: require("../../assets/icons/16px/icon_bell_16px.svg"),
  building_16px: require("../../assets/icons/16px/icon_building_16px.svg"),
  calendar_16px: require("../../assets/icons/16px/icon_calendar_16px.svg"),
  calendar_date_16px: require("../../assets/icons/16px/icon_calendar_date_16px.svg"),
  chart_16px: require("../../assets/icons/16px/icon_chart_16px.svg"),
  close_circle_16px: require("../../assets/icons/16px/icon_close_circle_16px.svg"),
  copy_16px: require("../../assets/icons/16px/icon_copy_16px.svg"),
  dashboard_16px: require("../../assets/icons/16px/icon_dashboard_16px.svg"),
  doctor_16px: require("../../assets/icons/16px/icon_doctor_16px.svg"),
  done_circle_16px: require("../../assets/icons/16px/icon_done_circle_16px.svg"),
  download_16px: require("../../assets/icons/16px/icon_download_16px.svg"),
  edit_16px: require("../../assets/icons/16px/icon_edit_16px.svg"),
  emergency_16px: require("../../assets/icons/16px/icon_emergency_16px.svg"),
  exclamation_mark_circle_16px: require("../../assets/icons/16px/icon_exclamation_mark_circle_16px.svg"),
  face_ok_16px: require("../../assets/icons/16px/icon_face_ok_16px.svg"),
  face_sad_16px: require("../../assets/icons/16px/icon_face_sad_16px.svg"),
  face_smile_16px: require("../../assets/icons/16px/icon_face_smile_16px.svg"),
  folder_16px: require("../../assets/icons/16px/icon_folder_16px.svg"),
  form_16px: require("../../assets/icons/16px/icon_form_16px.svg"),
  form_add_16px: require("../../assets/icons/16px/icon_form_add_16px.svg"),
  form_done_16px: require("../../assets/icons/16px/icon_form_done_16px.svg"),
  forms_16px: require("../../assets/icons/16px/icon_forms_16px.svg"),
  gear_16px: require("../../assets/icons/16px/icon_gear_16px.svg"),
  globe_16px: require("../../assets/icons/16px/icon_globe_16px.svg"),
  heart_16px: require("../../assets/icons/16px/icon_heart_16px.svg"),
  heart_pulse_16px: require("../../assets/icons/16px/icon_heart_pulse_16px.svg"),
  home_16px: require("../../assets/icons/16px/icon_home_16px.svg"),
  info_circle_16px: require("../../assets/icons/16px/icon_info_circle_16px.svg"),
  light_16px: require("../../assets/icons/16px/icon_light_16px.svg"),
  list_bullet_16px: require("../../assets/icons/16px/icon_list_bullet_16px.svg"),
  location_16px: require("../../assets/icons/16px/icon_location_16px.svg"),
  lock_closed_16px: require("../../assets/icons/16px/icon_lock_closed_16px.svg"),
  lock_open_16px: require("../../assets/icons/16px/icon_lock_open_16px.svg"),
  logout_17_16px: require("../../assets/icons/16px/icon_logout_16px-17.svg"),
  logout_50_16px: require("../../assets/icons/16px/icon_logout_16px-50.svg"),
  mail_16px: require("../../assets/icons/16px/icon_mail_16px.svg"),
  medicare_16px: require("../../assets/icons/16px/icon_medicare_16px.svg"),
  medicine_16px: require("../../assets/icons/16px/icon_medicine_16px.svg"),
  medkit_16px: require("../../assets/icons/16px/icon_medkit_16px.svg"),
  megaphone_16px: require("../../assets/icons/16px/icon_megaphone_16px.svg"),
  hamburger_16px: require("../../assets/icons/16px/icon_menu_hamburger_16px.svg"),
  message_16px: require("../../assets/icons/16px/icon_message_16px.svg"),
  messages_16px: require("../../assets/icons/16px/icon_messages_16px.svg"),
  more_horizontal_16px: require("../../assets/icons/16px/icon_more_horizontal_16px.svg"),
  more_vertical_16px: require("../../assets/icons/16px/icon_more_vertical_16px.svg"),
  new_window_16px: require("../../assets/icons/16px/icon_new_window_16px.svg"),
  news_16px: require("../../assets/icons/16px/icon_news_16px.svg"),
  pencil_16px: require("../../assets/icons/16px/icon_pencil_16px.svg"),
  persons_16px: require("../../assets/icons/16px/icon_persons_16px.svg"),
  phone_16px: require("../../assets/icons/16px/icon_phone_16px.svg"),
  picture_16px: require("../../assets/icons/16px/icon_picture_16px.svg"),
  questionmark_circle_16px: require("../../assets/icons/16px/icon_questionmark_circle_16px.svg"),
  remove_16px: require("../../assets/icons/16px/icon_remove_16px.svg"),
  remove_circle_16px: require("../../assets/icons/16px/icon_remove_circle_16px.svg"),
  review_tick_16px: require("../../assets/icons/16px/icon_review_tick_16px.svg"),
  search_16px: require("../../assets/icons/16px/icon_search_16px.svg"),
  send_16px: require("../../assets/icons/16px/icon_send_16px.svg"),
  smartphone_16px: require("../../assets/icons/16px/icon_smartphone_16px.svg"),
  sort_16px: require("../../assets/icons/16px/icon_sort_16px.svg"),
  star_16px: require("../../assets/icons/16px/icon_star_16px.svg"),
  star_add_16px: require("../../assets/icons/16px/icon_star_add_16px.svg"),
  star_disabled_16px: require("../../assets/icons/16px/icon_star_disabled_16px.svg"),
  subtract_circle_16px: require("../../assets/icons/16px/icon_substract_circle_16px.svg"),
  trashcan_16px: require("../../assets/icons/16px/icon_trashcan_16px.svg"),
  triangle_16px: require("../../assets/icons/16px/icon_triangle_16px.svg"),
  upload_16px: require("../../assets/icons/16px/icon_upload_16px.svg"),
  user_16px: require("../../assets/icons/16px/icon_user_16px.svg"),
  user_add_16px: require("../../assets/icons/16px/icon_user_add_16px.svg"),
  user_create_16px: require("../../assets/icons/16px/icon_user_create_16px.svg"),
  user_search_16px: require("../../assets/icons/16px/icon_user_search_16px.svg"),
  video_16px: require("../../assets/icons/16px/icon_video_16px.svg"),
  view_16px: require("../../assets/icons/16px/icon_view_16px.svg"),
  view_disabled_16px: require("../../assets/icons/16px/icon_view_disabled_16px.svg"),
  wrench_16px: require("../../assets/icons/16px/icon_wrench_16px.svg"),
  zoom_in_16px: require("../../assets/icons/16px/icon_zoom_in_16px.svg"),
  zoom_out_16px: require("../../assets/icons/16px/icon_zoom_out_16px.svg"),

  // 24px icon assets
  face_smile_24px: require("../../assets/icons/24px/icon_face_smile_24px.svg"),
  absences: require("../../assets/icons/24px/absences.svg"),
  alert_circle: require("../../assets/icons/24px/alert_circle.svg"),
  arrowhead_down: require("../../assets/icons/24px/arrowhead_down.svg"),
  building: require("../../assets/icons/24px/building.svg"),
  forms: require("../../assets/icons/24px/forms.svg"),
  persons: require("../../assets/icons/24px/persons.svg"),
  user_create: require("../../assets/icons/24px/user_create.svg"),
  user_search: require("../../assets/icons/24px/user_search.svg"),
  video: require("../../assets/icons/24px/video.svg"),
  view: require("../../assets/icons/24px/icon_view_24px.svg"),
  ok_circle: require("../../assets/icons/24px/ok_circle.svg"),
  analytics: require("../../assets/icons/24px/analytics.svg"),
  analytics_circle: require("../../assets/icons/24px/analytics_circle.svg"),
  arrow_down: require("../../assets/icons/24px/arrow_down.svg"),
  arrow_left: require("../../assets/icons/24px/arrow_left.svg"),
  arrow_right: require("../../assets/icons/24px/arrow_right.svg"),
  arrow_up: require("../../assets/icons/24px/arrow_up.svg"),
  arrow_round_left: require("../../assets/icons/24px/arrow_round_left.svg"),
  arrow_round_right: require("../../assets/icons/24px/arrow_round_right.svg"),
  attachment: require("../../assets/icons/24px/attachment.svg"),
  bell: require("../../assets/icons/24px/bell.svg"),
  bulp: require("../../assets/icons/24px/bulp.svg"),
  calendar: require("../../assets/icons/24px/calendar.svg"),
  chart: require("../../assets/icons/24px/chart.svg"),
  chart_2: require("../../assets/icons/24px/chart_2.svg"),
  close_circle: require("../../assets/icons/24px/close_circle.svg"),
  dashboard: require("../../assets/icons/24px/dashboard.svg"),
  direction_down: require("../../assets/icons/24px/direction_down.svg"),
  direction_left: require("../../assets/icons/24px/direction_left.svg"),
  direction_right: require("../../assets/icons/24px/direction_right.svg"),
  direction_up: require("../../assets/icons/24px/direction_up.svg"),
  doctor: require("../../assets/icons/24px/doctor.svg"),
  download_24px: require("../../assets/icons/24px/download.svg"),
  email: require("../../assets/icons/24px/email.svg"),
  file: require("../../assets/icons/24px/file.svg"),
  folder: require("../../assets/icons/24px/folder.svg"),
  gear: require("../../assets/icons/24px/gear.svg"),
  globe: require("../../assets/icons/24px/globe.svg"),
  healthbag: require("../../assets/icons/24px/healthbag.svg"),
  heart: require("../../assets/icons/24px/heart.svg"),
  heartbeat: require("../../assets/icons/24px/heartbeat.svg"),
  home: require("../../assets/icons/24px/home.svg"),
  image: require("../../assets/icons/24px/image.svg"),
  picture: require("../../assets/icons/24px/image.svg"),
  lock_open: require("../../assets/icons/24px/lock_open.svg"),
  lock: require("../../assets/icons/24px/lock.svg"),
  logout: require("../../assets/icons/24px/logout.svg"),
  menstruation: require("../../assets/icons/24px/menstruation.svg"),
  message: require("../../assets/icons/24px/message.svg"),
  messages: require("../../assets/icons/24px/messages.svg"),
  messages_black: require("../../assets/icons/24px/messages.svg"),
  person: require("../../assets/icons/24px/person.svg"),
  phone: require("../../assets/icons/24px/phone.svg"),
  pill_2: require("../../assets/icons/24px/pill_2.svg"),
  question_circle: require("../../assets/icons/24px/question_circle.svg"),
  remove: require("../../assets/icons/24px/remove.svg"),
  trashcan: require("../../assets/icons/24px/trashcan.svg"),
  send: require("../../assets/icons/24px/send.svg"),
  user: require("../../assets/icons/24px/user.svg"),
  user_fill: require("../../assets/icons/24px/user_fill.svg"),
  users: require("../../assets/icons/24px/users.svg"),
  zoom: require("../../assets/icons/24px/zoom-in.svg"),
  zoom_out: require("../../assets/icons/24px/zoom-out.svg"),
  loader_dots: require("../../assets/icons/24px/loader-dots.svg"),
  loader_circle: require("../../assets/icons/24px/loader-circle.svg"),
  sad_emoji: require("../../assets/icons/24px/sad.svg"),
  serious_emoji: require("../../assets/icons/24px/serious.svg"),
  smile_emoji: require("../../assets/icons/24px/smile.svg"),
  vigilance_triangle: require("../../assets/icons/24px/vigilance_triangle.svg"),
  mobile_person: require("../../assets/icons/24px/mobile_person.svg"),
  more_h: require("../../assets/icons/24px/icon_more_horiz_24px.svg"),
  edit_file: require("../../assets/icons/24px/icon_edit_24px.svg"),
  star: require("../../assets/icons/24px/icon_star_24px.svg"),
  disabled: require("../../assets/icons/24px/icon_remove_circle_24px.svg"),
  remove_circle: require("../../assets/icons/24px/icon_remove_circle_24px.svg"),
  rm_file: require("../../assets/icons/24px/icon_delete_24px.svg"),
  list: require("../../assets/icons/24px/icon_list_bulleted_24px.svg"),
  kebab: require("../../assets/icons/24px/icon_more_vert_24px.svg"),
  more_vertical: require("../../assets/icons/24px/more_vertical.svg"),
  info_circle: require("../../assets/icons/24px/icon_info_24px.svg"),
  download: require("../../assets/icons/24px/icon_download_24px.svg"),
  upload: require("../../assets/icons/24px/icon_upload_24px.svg"), // needs 48px, too.
  access_time: require("../../assets/icons/24px/icon_time_24px.svg"),
  search: require("../../assets/icons/24px/icon_search_24px.svg"),
  add_user: require("../../assets/icons/24px/icon_person_add_24px.svg"),
  add: require("../../assets/icons/24px/icon_add_24px.svg"),
  hr: require("../../assets/icons/24px/icon_assignment_24px.svg"),
  new_tab: require("../../assets/icons/24px/newtab.svg"),
  form_24px: require("../../assets/icons/24px/form_24px.svg"),

  // 32px icon assets - we don't use 32px icons anymore, these should be removed
  hamburger: require("../../assets/icons/32px/hamburger.svg"),

  // 48px icon assets
  alert_circle_48px: require("../../assets/icons/48px/alert_circle.svg"),
  arrow_left_48px: require("../../assets/icons/48px/arrow_left.svg"),
  arrow_round_left_48px: require("../../assets/icons/48px/arrow_round_left.svg"),
  arrow_round_right_48px: require("../../assets/icons/48px/arrow_round_right.svg"),
  chart_2_48px: require("../../assets/icons/48px/chart_2.svg"),
  exclamation_mark_triangle_48px: require("../../assets/icons/48px/exclamation_mark_triangle.svg"),
  ok_circle_48px: require("../../assets/icons/48px/ok_circle.svg"),
  info_circle_48px: require("../../assets/icons/48px/info_circle.svg"),
  close_circle_48px: require("../../assets/icons/48px/close_circle.svg"),
  remove_circle_48px: require("../../assets/icons/48px/remove_circle.svg"),
  bulp_48px: require("../../assets/icons/48px/bulp.svg"),
  doctor_48px: require("../../assets/icons/48px/doctor.svg"),
  dashboard_48px: require("../../assets/icons/48px/dashboard.svg"),
  form_done_48px: require("../../assets/icons/48px/form_done.svg"),
  gear_48px: require("../../assets/icons/48px/gear.svg"),
  globe_48px: require("../../assets/icons/48px/globe.svg"),
  healthbag_48px: require("../../assets/icons/48px/healthbag.svg"),
  mail: require("../../assets/icons/48px/mail.svg"),
  person_48px: require("../../assets/icons/48px/person.svg"),
  client_avatar: require("../../assets/icons/48px/client_avatar.svg"),
  nurse_avatar: require("../../assets/icons/48px/nurse_avatar.svg"),
  doctor_avatar: require("../../assets/icons/48px/doctor_avatar.svg"),
  nonmedicalstaff_avatar: require("../../assets/icons/48px/admin_avatar.svg"),
  studymonitor_avatar: require("../../assets/icons/48px/admin_avatar.svg"),
  admin_avatar: require("../../assets/icons/48px/admin_avatar.svg"),
  usergroup_avatar: require("../../assets/icons/48px/usergroup_avatar.svg"),
  mobile_person_48px: require("../../assets/icons/48px/mobile_person.svg"),
  megaphone_48px: require("../../assets/icons/48px/icon_megaphone_48px.svg"),
  picture_48px: require("../../assets/icons/48px/picture.svg"),
  trashcan_48px: require("../../assets/icons/48px/trashcan.svg"),
  outcome_non_urgent: require("../../assets/icons/48px/outcome_non_urgent.svg"),
  outcome_urgent: require("../../assets/icons/48px/outcome_urgent.svg"),
  outcome_emergency: require("../../assets/icons/48px/outcome_emergency.svg"),
  form_48px: require("../../assets/icons/48px/form_48px.svg"),
  heart_48px: require("../../assets/icons/48px/heart.svg"),
  heart_2: require("../../assets/icons/48px/heart_2.svg"),
};

export type IconName = keyof typeof icons;

const Icon = ({
  align = "default",
  badge,
  name,
  size = "default",
  className,
  color,
  material,
  alt,
  role,
  ...rest
}: IconProps) => {
  const iconContainerRef = useRef<HTMLSpanElement>(null);
  const hasTabindex =
    iconContainerRef.current?.attributes.getNamedItem("tabindex")?.value !==
    undefined;

  return (
    <IconContainer
      ref={iconContainerRef}
      align={align}
      badge={badge}
      aria-hidden={!alt && !hasTabindex}
      {...rest}
    >
      <RawSvgImage
        size={size}
        currentColor={material}
        color={color}
        className={className}
        xlinkHref={`#${iconId(icons, name)}`}
        alt={alt}
        role={role}
      />
    </IconContainer>
  );
};

export type IconProps = React.HTMLAttributes<HTMLSpanElement> & {
  /** Name of the icon, also defining the showed icon */
  name: IconName;
  align?: string;
  /** Badge to be shown next to the icon */
  badge?: string | number;
  /** Whether the icon is from our icon set (false) or [google's material design set](https://design.google.com/icons/). Using our own icon set is preferred. */
  material?: boolean;
  /**
   * Alternative text for the icon - if not defined, this element is hidden from the accessibility tree
   * (and thus screen readers). Don't set an alt text unless you're sure it's needed - for example, if
   * the icon is adjacent to a text that has the same meaning as the icon, then an alt text is redundant. */
  alt?: string;
  role?: string;
} & Omit<RawSvgImageProps, "xlinkHref">;

export default Icon;
export { SvgImage, svgSize };
export type { AssetName };
