import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import Icon, { IconName } from "../Icon";
import {
  AvatarStyled,
  WithoutAvatarStyled,
  IAvatarStyledProps,
  User,
  avatarSize,
} from "./Avatar.styles";
import { colors } from "../../styles/variables";

const iconName = (user: User) => {
  const { type } = user || {};

  if (type && type === "KaikuAdministrator") {
    return "nurse_avatar";
  }

  const prefix = type ? type.toLowerCase() : "usergroup";

  return prefix.concat("_avatar") as IconName;
};

/**
  Use an Avatar to show a user's picture. Usually, you should be using UserList to represent a
  user or users, because UserList also displays the name, role and other relevant information
  in addition to the avatar.

  The avatar displays the user's picture or, if it's not available, a role-specific icon.
*/

const AvatarWrapper = ({
  user,
  size,
  children,
  hide,
  intl: { formatMessage },
  ...props
}: IAvatarStyledProps & { intl: IntlShape }) => {
  const { avatar } = user || {};
  const alt = formatMessage({ id: "people.user_avatar" });

  if (avatar && !hide) {
    return (
      <AvatarStyled
        style={{ backgroundImage: `url(${avatar})` }}
        size={size}
        role="img"
        title={alt}
        {...props}
      >
        {children}
      </AvatarStyled>
    );
  } else {
    return (
      <WithoutAvatarStyled size={size} {...props}>
        <Icon
          color={colors.gray950}
          name={iconName(user)}
          size={avatarSize(size)}
          alt={alt}
          role="img"
        />
        {children}
      </WithoutAvatarStyled>
    );
  }
};

export const Avatar = ({ children, ...props }: any) => (
  <AvatarWrapper {...props}>{children}</AvatarWrapper>
);

export default injectIntl(Avatar);
