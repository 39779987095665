import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "../../styles/styleHelpers";
import { colors } from "../../styles/variables";
import Icon from "../Icon";

const checkboxHeight = 22;
const checkboxWidth = 22;
const labelMargin = 16;

type CheckBoxLabelProps = {
  disabled?: boolean;
  readOnly?: boolean;
  labelHidden: boolean;
};

type CheckBoxInputWrapperProps = {
  checked: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error: boolean;
};

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;
  margin-bottom: 8px;
`;

export const CheckBoxInputWrapper = styled.div<CheckBoxInputWrapperProps>`
  position: relative;
  width: ${checkboxWidth + "px"};
  height: ${checkboxHeight + "px"};
  flex-shrink: 0;

  & input {
    opacity: 0;
  }

  & input + label::before {
    border-width: 1px;
    border-style: solid;
    border-color: ${({ error }) => (error ? colors.red700 : colors.gray700)};
    border-radius: 1px;
    content: "";
    height: ${checkboxHeight + "px"};
    ${rtl`left: 0;`}
    position: absolute;
    top: 0;
    width: ${checkboxWidth + "px"};
    background-color: ${({ disabled, readOnly, checked }) => {
      if (disabled || readOnly) {
        if (checked) {
          return colors.gray500;
        }
        return colors.gray300;
      }
      return colors.white;
    }};
  }

  & input + label::after {
    content: "";
    color: ${({ disabled }) => (disabled ? colors.white : colors.gray900)};
    border: 3px solid;
    ${rtl`
      border-left: 0;
      left: 7px;
    `}
    border-top: 0;
    height: 14px;
    opacity: 0;
    position: absolute;
    top: 1px;
    transform: rotate(${({ theme: { dir } }) => (dir === "rtl" ? -45 : 45)}deg);
    transition: opacity 0.2s ease-in-out;
    width: 8px;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  & input:hover + label::after {
    opacity: ${({ disabled }) => (disabled ? 0 : 0.2)};
  }

  & input:checked + label::after {
    opacity: 1;
  }

  & input[data-focus-visible-added] + label::before {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.blue700};
    outline-offset: 2px;
    border-radius: 2px;
  }

  & input[data-focus-visible-added] + label::before {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.blue700};
    outline-offset: 2px;
    border-radius: 2px;
  }
`;

const labelHiddenStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const CheckBoxLabel = styled.label<CheckBoxLabelProps>`
  ${rtl`margin-left: ${labelMargin}px;`}
  display: block;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ disabled }) => (disabled ? colors.gray700 : colors.gray950)};
  ${({ labelHidden }) => labelHidden && labelHiddenStyles};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${rtl`margin-left: ${checkboxWidth + labelMargin}px;`};
`;

export const ErrorIcon = styled(Icon)`
  color: ${colors.red700};
  margin-right: 8px;
  width: 16px;
  height: 16px;
`;

export const ErrorLabel = styled.div`
  color: ${colors.red700};
  font-size: ${rem(14)};
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const CheckMarkLabel = styled.span`
  display: none;
`;
