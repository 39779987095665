import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  card,
  spacing,
  typography,
  mediaQueries,
} from "../../styles/variables";
import { CardType, Props } from ".";
import { mediaMax } from "../../styles/mixins/mediaQueryMixins";

const successStyle = `
    background: ${colors.green50};
    color: ${colors.green900};
    fill: ${colors.green900};
`;

const warningStyle = `
    background: ${colors.yellow50};
    color: ${colors.yellow900};
    fill: ${colors.yellow900};
`;

const alertStyle = `
    background: ${colors.red50};
    color: ${colors.red900};
    fill: ${colors.red900};
`;

const neutralStyle = `
    background: ${colors.blue50};
    color: ${colors.blue900};
    fill: ${colors.blue900};
`;

export const StyledCard = styled.div.attrs(() => ({
  "data-testid": "card",
}))<{ type?: CardType; noPadding: boolean }>`
  border: ${card.border} 1px solid;
  border-radius: ${card.borderRadius};
  margin: ${spacing.s}px 0;
  display: flex;
  padding: ${card.padding}px;
  align-items: baseline;

  ${({ type }) => type === "white" && `background: ${colors.white};`}
  ${({ type }) => type === "success" && successStyle}
  ${({ type }) => type === "warning" && warningStyle}
  ${({ type }) => type === "alert" && alertStyle}
  ${({ type }) => type === "neutral" && neutralStyle}

  ${({ noPadding }) => noPadding && `padding: 0;`}

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaMax(mediaQueries.small)} {
    padding: ${card.paddingSmall}px;
  }
`;

export const Container = styled.div.attrs(() => ({
  "data-testid": "card-container",
}))`
  flex-grow: 1;
`;

export const IconContainer = styled.div`
  ${rtl`padding-right: ${card.padding}px;`}

  ${mediaMax(mediaQueries.small)} {
    ${rtl`padding-right: ${card.paddingSmall}px;`}
  }
`;

export const HeadingContainer = styled.div`
  padding-bottom: 0;

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: ${typography.fontSizeBase};
    text-transform: none;
    letter-spacing: 1.5px;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

export const Content = styled.div.attrs(() => ({
  "data-testid": "card-content",
}))<{ heading?: Props["heading"] }>`
  padding: 0;

  padding-top: ${props => (props.heading ? card.padding / 2 : 0)}px;

  ${mediaMax(mediaQueries.small)} {
    padding-top: ${props => (props.heading ? card.paddingSmall / 2 : 0)}px;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
