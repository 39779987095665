import React from "react";
import { get, defaults } from "lodash";
import { SvgSize } from "./SvgImage.types";
import { SvgContainer } from "./SvgImage.styles";

const assets = {
  origama_speech_bubble_questionmark: require("../../assets/origama/origama_speech_bubble_questionmark.svg"),
  origama_origama_file: require("../../assets/origama/origama_origama_file.svg"),
  origama_files: require("../../assets/origama/origama_files.svg"),

  beaker: require("../../assets/beaker.svg"),
  rocket: require("../../assets/rocket.svg"),
  handshake: require("../../assets/handshake.svg"),
  identity_confirmed: require("../../assets/identity_confirmed.svg"),
  development_improved: require("../../assets/development_improved.svg"),
  development_same: require("../../assets/development_same.svg"),
  development_worsened: require("../../assets/development_worsened.svg"),
  caregiver_user: require("../../assets/caregiver_user.svg"),
  invitation_sent: require("../../assets/invitation_sent.svg"),
};

export type AssetName = keyof typeof assets;

export const iconId = <T,>(obj: T, name: keyof T) =>
  defaults(get(obj[name], "default.id"), name);

export const RawSvgImage = ({
  xlinkHref,
  size,
  currentColor,
  color,
  className,
  alt,
  role,
}: RawSvgImageProps) => (
  <SvgContainer
    size={size}
    color={color}
    currentColor={currentColor}
    className={className}
    role={role}
  >
    {alt && <title>{alt}</title>}
    <use xlinkHref={xlinkHref} />
  </SvgContainer>
);

export type RawSvgImageProps = {
  xlinkHref: string;
  className?: string;
  /* Size of the component, can be chosen from the list of available sizes in ImageSize or a raw CSS value */
  size?: SvgSize | string;
  /* Color value */
  color?: string;
  /* Use currentColor as SVG fill color */
  currentColor?: boolean;
  /* Alterntative text for the graphic for use with assistive technology */
  alt?: string;
  role?: string;
};

const SvgImage = (
  props: Omit<RawSvgImageProps, "xlinkHref"> & { name: AssetName },
) => <RawSvgImage {...props} xlinkHref={`#${iconId(assets, props.name)}`} />;

export default SvgImage;
