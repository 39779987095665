import rtl from "styled-components-rtl";
import styled from "styled-components";
import { spacing as spaces, SpacingKey } from "../../styles/variables";

export type StyledProps = {
  inline?: any;
  spacing?: SpacingKey;
  top?: SpacingKey;
  left?: SpacingKey;
  right?: SpacingKey;
  bottom?: SpacingKey;
};

const Spacing = styled.div.attrs(({ spacing }: StyledProps) => ({
  "data-testid": "spacing",
  className: `spacing-${spacing ?? "m"}`,
}))<StyledProps>`
  ${({ inline }) => inline && `display: inline-block;`}
  ${({ spacing }) => `margin: ${spaces[spacing ?? "m"] ?? 0}px;`}
  ${({ top }) => top && `margin-top: ${spaces[top] ?? 0}px;`}
  ${({ left }) => left && rtl`margin-left: ${spaces[left] ?? 0}px;`}
  ${({ right }) => right && rtl`margin-right: ${spaces[right] ?? 0}px;`}
  ${({ bottom }) => bottom && `margin-bottom: ${spaces[bottom] ?? 0}px;`}
`;

/** @component */
export default Spacing;
