import styled from "styled-components";
import { colors, spacing } from "../../styles/variables";
import rtl from "styled-components-rtl";

export const Separator = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:before,
  &:after {
    content: "";
    border-top: 1px solid ${colors.gray300};
    ${rtl`margin: 0 ${spacing.s}px 0 0`};
    flex: 1 0 ${spacing.s}px;
  }

  &:after {
    ${rtl`margin: 0 0 0 ${spacing.s}px`};
  }
`;
