import styled, { css } from "styled-components";
import { ButtonType } from ".";
import { typography, colors, primaryColor } from "../../styles/variables";
import Icon, { IconName } from "../Icon";

type ButtonProps = {
  $buttonType?: ButtonType;
  size?: "small" | "default";
  $isPrimaryButton?: boolean;
  icon?: IconName;
  iconEdge?: "left" | "right";
};

const fontWeight = 500;

const danger = css`
  background: ${props => props.theme.buttons.danger.background};
  color: ${props => props.theme.buttons.danger.text};
  border: ${props => props.theme.buttons.danger.border} 1px solid;
  &:link,
  &:visited {
    color: ${props => props.theme.buttons.danger.text};
  }
  &:hover,
  &:active {
    color: ${colors.white};
    background: ${props => props.theme.buttons.danger.hoverBackground};
    border-color: ${props => props.theme.buttons.danger.hoverBorder};
  }
`;

const primary = css`
  color: ${colors.white};
  background: ${primaryColor};
  border-color: ${primaryColor};
  &:link,
  &:visited {
    color: ${colors.white};
  }
  &:hover,
  &:active {
    color: ${colors.white};
    background: ${props => props.theme.buttons.hover};
    border-color: ${props => props.theme.buttons.hover};
  }
`;

const secondary = css`
  color: ${props => props.theme.buttons.secondary.text};
  background: ${props => props.theme.buttons.secondary.background};
  border-color: ${props => props.theme.buttons.secondary.border};
  &:link,
  &:visited {
    color: ${colors.white};
  }
  &:hover,
  &:active {
    color: ${colors.white};
    background: ${props => props.theme.buttons.hover};
    border-color: ${props => props.theme.buttons.hover};
  }
`;

const ghost = css`
  color: ${props => props.theme.buttons.ghost.text};
  background: ${props => props.theme.buttons.ghost.background};
  border-color: transparent;
  &:link,
  &:visited {
    color: ${colors.blue500};
  }
  &:hover,
  &:active {
    color: ${props => props.theme.buttons.ghost.hoverColor};
    background: ${props => props.theme.buttons.ghost.background};
    border-color: transparent;
    text-decoration: underline;
  }
`;

const accent = css`
  background: ${props => props.theme.buttons.accent.background};
  color: ${props => props.theme.buttons.accent.text};
  border: ${props => props.theme.buttons.accent.border} 2px solid;
  &:link,
  &:visited {
    color: ${props => props.theme.buttons.accent.text};
  }
  &:hover,
  &:active {
    color: ${props => props.theme.buttons.hover};
    background: ${props => props.theme.buttons.accent.hoverBackground};
    border-color: ${props => props.theme.buttons.accent.hoverBorder};
  }
  &:disabled,
  &:disabled:hover {
    background: ${props => props.theme.buttons.accent.background};
    border-color: ${colors.gray500};
    color: ${colors.gray500};
    cursor: not-allowed;
  }
`;

const buttonStyles = {
  danger,
  primary,
  accent,
  secondary,
  ghost,
};

const defaultButtonStyles = css`
  padding: 1rem 2rem;
  font-weight: ${fontWeight};
`;

export const ButtonStyled = styled.button.attrs(
  ({ $buttonType, $isPrimaryButton }: ButtonProps) => ({
    "data-testid":
      $buttonType || $isPrimaryButton
        ? `styled-button-${$isPrimaryButton ? "primary" : $buttonType}`
        : "styled-button",
  }),
)<ButtonProps>`
  position: relative;
  display: inline-block;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: ${props => props.theme.buttons.primary.background};
  color: ${props => props.theme.buttons.primary.text};
  border: 1px ${props => props.theme.buttons.primary.background} solid;
  border-radius: ${props => props.theme.buttons.borderRadius}px;
  padding: 0.5rem 1rem;
  transition:
    color 0.2s,
    background 0.2s,
    border-color 0.2s;
  margin: 0.25rem;
  line-height: 1;
  white-space: nowrap;
  font-family: ${typography.fontFamilyBase};
  font-weight: ${fontWeight};
  font-size: ${typography.fontSizeMedium};
  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: 4px;
  }
  &:link,
  &:visited {
    color: ${props => props.theme.buttons.primary.text};
  }
  &:hover,
  &:active {
    background: ${props => props.theme.buttons.primary.hoverBackground};
    border-color: ${props => props.theme.buttons.primary.hoverBackground};
    color: ${props => props.theme.buttons.primary.text};
  }
  &:disabled,
  &:disabled:hover {
    background: ${colors.gray700};
    border-color: ${colors.gray700};
    color: ${colors.white};
    cursor: not-allowed;
  }
  & * {
    cursor: pointer;
  }
  ${({ $buttonType }) => $buttonType && buttonStyles[$buttonType]}
  ${({ size }) => size === "default" && defaultButtonStyles}
  ${({ $isPrimaryButton }) => $isPrimaryButton && buttonStyles["primary"]}
`;

export const ButtonIconStyled = styled(Icon)`
  top: -1px;
  position: relative;
  float: ${({ align }) => (align === "left" ? "left" : "right")};
`;

export const LinkButtonStyled = styled.div``;

export const ButtonSpinnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: -4px auto;
`;

export default ButtonStyled;
