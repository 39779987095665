import React, { SyntheticEvent } from "react";
import * as Styles from "./CheckBox.styles";
import {
  CheckBoxContainer,
  CheckBoxInputWrapper,
  CheckBoxLabel,
  CheckMarkLabel,
  ErrorContainer,
  ErrorIcon,
  ErrorLabel,
} from "./CheckBox.styles";

export type CheckBoxProps = JSX.IntrinsicElements["input"] & {
  id: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  errorLabel?: string;
  onChange(options?: React.SyntheticEvent<HTMLInputElement>): void;
  label?: string | React.ReactNode;
  labelTestId?: string;
  labelHidden?: boolean;
  value?: string;
};

function CheckBox(props: CheckBoxProps) {
  const {
    id,
    name,
    disabled = false,
    readOnly = false,
    checked = false,
    error = false,
    onChange,
    label,
    labelTestId,
    labelHidden = false,
    errorLabel,
    value,
    ...rest
  } = props;

  const interactable = !disabled && !readOnly;

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (interactable && event.key === "Enter") {
      onChange(event);
    }
  };

  return (
    <>
      <CheckBoxContainer>
        <CheckBoxInputWrapper
          data-testid={`CheckBoxInputWrapper-${id}`}
          disabled={disabled}
          readOnly={readOnly}
          checked={checked}
          error={error}
        >
          <input
            {...rest}
            id={id}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (interactable) {
                onChange(event);
              }
            }}
            onKeyPress={onEnter}
            value={value}
          />
          <label htmlFor={id}>
            <CheckMarkLabel>{label}</CheckMarkLabel>
          </label>
        </CheckBoxInputWrapper>
        {label && (
          <CheckBoxLabel
            data-testid={labelTestId}
            htmlFor={id}
            labelHidden={labelHidden}
            disabled={disabled}
            readOnly={readOnly}
          >
            {label}
          </CheckBoxLabel>
        )}
      </CheckBoxContainer>
      {error && errorLabel && (
        <ErrorContainer data-testid="checkbox-error-container">
          <ErrorIcon name="alert_circle" />
          <ErrorLabel data-testid="checkbox-error-label">
            {errorLabel}
          </ErrorLabel>
        </ErrorContainer>
      )}
    </>
  );
}

export default CheckBox;
export { Styles };
