import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "../../styles/styleHelpers";
import { colors } from "../../styles/variables";
import { fadeIn } from "../../styles/animations";
import Icon from "../Icon";

export type AccordionColorTheme = "blue" | "white" | "blank" | "transparent";
interface IAccordion {
  color: AccordionColorTheme;
}

type AccordionOpen = {
  $direction?: "horizontal" | "vertical";
  $open: boolean;
};

const accordionColorStyles = {
  blue: css`
    background: ${colors.blue50};
  `,
  purple: css`
    background: ${colors.blue50};
    ${rtl`border-left: 0.4em solid #860052;`}

    label, svg {
      color: #860052;
    }
  `,
  white: css`
    background: ${colors.white};
    ${rtl`border-left: 0.4em solid ${colors.blue500};`}
  `,
  blank: css`
    background: ${colors.white};
    border: 0.1em solid ${colors.blue500};
  `,
  transparent: css`
    background: transparent;
    border: none;
  `,
};

const contentOpen = css`
  display: block;
  animation: ${fadeIn} 0.4s;
`;

export const AccordionStyled = styled.div<IAccordion>`
  border-radius: 0.5em;
  border: 0.1em solid ${colors.blue100};
  margin: 1em 0;
  ${({ color }) => color && accordionColorStyles[color]}
`;

export const HeadingContainer = styled.button.attrs(() => ({
  "data-testid": "accordion-heading-container",
  type: "button",
}))`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-color: transparent;
  background: none;
  font-size: ${rem(16)};
  ${rtl`text-align: left;`}
  padding: 0;
  border-width: 0;
  line-height: inherit;

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: -${rem(6)};
    border-radius: ${rem(10)};
  }
`;

export const Heading = styled.label`
  padding: 1em;
  margin: 0;
  cursor: pointer;
  color: ${colors.blue500};
  font-weight: bold;
`;

export const Content = styled.div<AccordionOpen>`
  display: none;
  padding: 1em;
  &:first-child {
    margin-top: 0px;
  }
  ${({ $open }) => $open && contentOpen}
`;

export const AccordionIcon = styled(Icon)<AccordionOpen>`
  ${rtl`float: right;`}
  transition: all 0.2s;
  margin-top: 3px;
  ${rtl`margin-right: 20px;`}
  cursor: pointer;
  color: ${colors.blue500};
  ${({ $open, $direction }) => {
    if ($open) {
      return $direction === "horizontal"
        ? css`
            ${rtl`transform: rotate(90deg);`}
          `
        : css`
            ${`transform: rotate(-90deg);`}
          `;
    }
    return (
      $direction === "vertical" &&
      css`
        ${`transform: rotate(90deg);`}
      `
    );
  }}
`;
