import styled, { keyframes } from "styled-components";
import rtl from "styled-components-rtl";
import { ISpinner } from "./index";
import { colors, scaffolding } from "../../styles/variables";
import { bounce } from "../../styles/animations";

export const Bounce1 = styled.div``;
export const Bounce2 = styled.div``;

export const Container = styled.div<ISpinner>`
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
  > div {
    width: 18px;
    height: 18px;
    ${rtl`margin-left: 2px;`}
    background-color: ${scaffolding.spinnerColor};
    border-radius: 100%;
    display: inline-block;
    animation: ${bounce} 1.4s infinite ease-in-out both;
  }
  > ${Bounce1} {
    animation-delay: -0.32s;
  }
  > ${Bounce2} {
    animation-delay: -0.16s;
  }
  ${({ noPadding }) => noPadding && `padding: 0;`}
  ${({ color }) => color && `background-color: ${color}`}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


// Circle spinner
const circleSizes = {
  tiny: "1rem",
  small: "1.25rem",
  medium: "2rem",
  large: "2.5rem",
  xlarge: "3rem",
};
export type CircleSpinnerSize = keyof typeof circleSizes;
export type CircleSpinnerProps = {
  color?: string;
  secondaryColor?: string;
  size?: CircleSpinnerSize;
};

export const CircleSpinner = styled.div<CircleSpinnerProps>`
  border: 4px solid ${({ color = "#dfdff6" }) => color};
  border-top: 4px solid ${({ secondaryColor = "#4549c3" }) => secondaryColor };
  border-radius: 50%;
  width: ${({size = "small"}) => circleSizes[size]};
  height: ${({size = "small"}) => circleSizes[size]};
  margin: 0 auto;
  animation: ${spin} 2s linear infinite;
`;
