import styled, { css } from "styled-components";
import { colors, typography } from "../../styles/variables";
import rem from "../../styles/styleHelpers";

import { SvgSize } from "./SvgImage.types";

export type SvgContainerProps = {
  size?: SvgSize | string;
  color?: string;
  currentColor?: boolean;
};

export const svgSize = (size?: SvgSize | string): string | undefined => {
  switch (size) {
    case "small":
      return rem(16);
    case "medium":
      return rem(24);
    case "large":
      return rem(48);
    case "xl":
      return rem(32);
    case "xxl":
      return rem(48);
    case "relative":
      return "1.5em";
  }

  return size;
};

const getColor = (color: string): string => {
  switch (color) {
    case "red":
      return colors.red700;
    case "yellow":
      return colors.yellow900;
    case "green":
      return colors.green700;
    default:
      return color;
  }
};

const svgSizeStyle = (size?: SvgSize | string) => {
  const sizeValue = svgSize(size);
  const sizeStyles = css`
    width: ${sizeValue};
    height: ${sizeValue};
  `;

  const relativeStyles = css`
    margin-top: -0.2rem;
  `;

  return css`
    ${sizeValue && sizeStyles}
    ${size === "relative" && relativeStyles}
  `;
};

export const SvgContainer = styled.svg.attrs(
  ({ size, color }: SvgContainerProps) => ({
    "data-testid": "icon-svg",
    "data-size": size,
    "data-color": color,
  }),
)<SvgContainerProps>`
  width: 1em;
  height: 1em;
  line-height: ${typography.baseLineHeight};
  vertical-align: middle;

  ${({ currentColor }) => currentColor && `fill: currentColor;`}
  ${({ color }) => color && `color: ${getColor(color)};`}
  ${({ size }) => svgSizeStyle(size)}

  ${props => props.theme.dir === "rtl" && "transform: scaleX(-1);"}
`;
