import { lighten } from "polished";
import colors2019 from "../styles/blueColors2019";
import rtl from "styled-components-rtl";
import { SiteSettings } from "../utils/holvikaari";
import { BaseColors } from "styled-components";

const site = SiteSettings.site;
const chuvGreen = "#17a345";
const hugGreen = "#93CFA9";
// Strahlentherapie colors
const schwabingDarkGreen = "#085B22";
const schwabingGreen = "#3fa83c";
// Krankenhaus Reinbek colors
const lachsOrange = "#f88066";
// const wasserBlau = "#1e7a9e";
// const hellGelb = "#fff2c0";

export default function rem(pxValue: number) {
  const htmlFontSize = 16;
  return `${pxValue / htmlFontSize}rem`;
}

// Functions below should be removed to site specific themes

export const getSiteSpecificColors = (colors: BaseColors): BaseColors => {
  const { blue50, blue100, blue500, blue700, blue900 } = colors2019;
  switch (site) {
    case "aa-pnh":
    case "hug":
      return { ...colors, blue50, blue100, blue500, blue700, blue900 };
    default:
      return colors;
  }
};

export const getSiteSpecificPrimaryColor = (blue: string) => {
  switch (site) {
    case "chuv":
      return chuvGreen;
    case "hug":
      return hugGreen;
    default:
      return blue;
  }
};

export const getSiteSpecificTaskBg = (taskBg: string) => {
  return taskBg;
};

export const getSiteSpecificOkBtnColor = (okBtnColor: string) => {
  return okBtnColor;
};

export const getSiteSpecificLinkColor = (linkColor: string) => {
  if (site === "strahlentherapie") {
    return schwabingGreen;
  }
  return linkColor;
};

export const getSiteSpecificLinkHoverColor = (linkColor: string) => {
  if (site === "strahlentherapie") {
    return schwabingDarkGreen;
  }
  const lightenLinkColor = lighten(0.07, linkColor);
  return lightenLinkColor;
};

export const getSiteSpecificFontFamilyHeadings = (ffHeading: string) => {
  switch (site) {
    case "hug":
      return '"Cooper Hewitt Medium", "Trebuchet MS", Helvetica, sans-serif';
    default:
      return ffHeading;
  }
};

export const getSiteSpecificFontFamilySansSerif = (ffSansSerif: string) => {
  switch (site) {
    case "hug":
      return '"Cooper Hewitt Book", Arial, Helvetica, sans-serif';
    default:
      return ffSansSerif;
  }
};

// Spacing
export type SpacingKey = "0" | "xxxs" | "xxs" | "xs" | "s" | "m" | "l" | "xl";
export const spacing: { [key in SpacingKey]: number } = {
  0: 0,
  xxxs: 4,
  xxs: 8,
  xs: 16,
  s: 32,
  m: 64,
  l: 128,
  xl: 256,
};

export type Spacing = keyof typeof spacing | number;
export const spacingPx = (s: Spacing) =>
  `${typeof s === "number" ? s : spacing[s]}px`;

// Padding
export const padding = (...args: Spacing[]) =>
  rtl`padding: ${args.map(spacingPx).join(" ")};`;
export const paddingLeft = (v: Spacing) => rtl`padding-left: ${spacingPx(v)};`;

// Margin
export const margin = (...args: Spacing[]) =>
  rtl`margin: ${args.map(spacingPx).join(" ")};`;
export const marginTop = (v: Spacing) => `margin-top: ${spacingPx(v)};`;
export const marginLeft = (v: Spacing) => rtl`margin-left: ${spacingPx(v)};`;
export const marginRight = (v: Spacing) => rtl`margin-right: ${spacingPx(v)};`;
export const marginBottom = (v: Spacing) => `margin-bottom: ${spacingPx(v)};`;

export const getSiteSpecificBadgeBg = (badgeBg: string) => {
  switch (site) {
    case "krankenhaus-reinbek":
      return lachsOrange;
    default:
      return badgeBg;
  }
};
