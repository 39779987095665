import rem from "./styleHelpers";

const textStyles = {
  smallTextSize: rem(14),
  smallTextLineHeight: rem(22),

  mediumTextSize: rem(16),
  mediumTextLineHeight: rem(24), // == 1.5

  largeTextSize: rem(18),
  largeTextLineHeight: rem(28),

  regularFontWeight: 400, // default
  mediumFontWeight: 500,

  extraLargeTextSize: rem(20),
};

export default textStyles;
