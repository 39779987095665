import { mediaQueries } from "../variables";

type ScreenBreakPoint = keyof typeof mediaQueries;
export type MediaQueryWidth = number | ScreenBreakPoint;

const widthToPx = (width: MediaQueryWidth) =>
  typeof width === "number" ? width : mediaQueries[width];

export const mediaMax = (maxWidth: MediaQueryWidth) =>
  `@media only screen and (max-width: ${widthToPx(maxWidth)}px)`;

export const mediaMin = (minWidth: MediaQueryWidth) =>
  `@media only screen and (min-width: ${widthToPx(minWidth)}px)`;

export const media = (minWidth: MediaQueryWidth, maxWidth: MediaQueryWidth) =>
  `@media only screen and (min-width: ${widthToPx(
    minWidth,
  )}px) and (max-width: ${widthToPx(maxWidth) - 1}px)`;

export const mediaPortrait = `@media only screen and (orientation: portrait)`;
export const mediaLandscape = `@media only screen and (orientation: landscape)`;
