// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { defaults } from "./general";

export const redirect = (url: string | URL, force = false) => {
  if (force) {
    window.onbeforeunload = null;
  }

  window.location.assign(url);
};

export const conversationsPath = ({ id }: any = {}) =>
  `/conversations/${defaults(id, "")}`;

export const userPath = (user, linkToEdit = false) =>
  linkToEdit ? `/people/${user.id}/edit` : `/clients/${user.id}`;

export const symptomsComparisonPath = user =>
  `/clients/${user.id}/input_form_answers/compare`;

export const inputFormAnswerCreateOrOpenPath = inputFormId =>
  `/input_forms/${inputFormId}/create_answer_or_open_existing`;

export const blueprintPerformPath = blueprintId =>
  `/blueprints/${blueprintId}/perform`;

export const SiteSettings =
  window.SiteSettings ||
  JSON.parse(
    document.querySelector("meta[name=application-settings]") &&
      document.querySelector("meta[name=application-settings]").content,
  );

export const defaultLocale =
  window.rails_locale || SiteSettings?.default_locale || "en";
