import styled from "styled-components";
import rtl from "styled-components-rtl";
import { transparentize } from "polished";
import {
  zIndexes,
  mediaQueries,
  typography,
  flash,
  colors,
} from "../../styles/variables";
import rem from "../../styles/styleHelpers";
import { mediaMax } from "../../styles/mixins/mediaQueryMixins";
import Icon from "../Icon";
import { FlashType } from "./Flash.types";

type FlashProps = { $type: FlashType };

const { small } = mediaQueries;
const { fontSizeMedium } = typography;

export const FlashStyled = styled.div.attrs(({ $type }: FlashProps) => ({
  "data-testid": `flash-${$type}`,
  role: "alert",
  "aria-atomic": true,
}))<FlashProps>`
  position: relative;
  padding: 1em;
  z-index: ${zIndexes.flash};
  display: flex;
  align-items: center;
  overflow: hidden;
  ${({ $type }) => `
    color: ${flash[$type].text};
    background: ${flash[$type].background};
  `}
  a {
    font-weight: 500;
    font-size: ${fontSizeMedium};
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
  // Icon container
  & > span {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: start;
    ${rtl`margin-right: 1em;`}
  }
  ${mediaMax(small)} {
    font-size: ${rem(14)};
  }
`;

export const Content = styled.div.attrs(() => ({
  "data-testid": `flash-content`,
}))`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: auto;
  overflow: hidden;
`;

export const CloseButton = styled.button.attrs(() => ({
  "data-testid": "flash-close-button",
}))<FlashProps>`
  border: none;
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  align-self: start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 24px;
  ${({ $type }) => `color: ${flash[$type].icon};`}

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: 2px;
    border-radius: 2px;
  }
`;

export const ReadMoreContainer = styled.div<FlashProps>`
  position: relative;
  height: 3em;
  ${({ $type }) => `
  background: ${flash[$type].background};
  background:linear-gradient(0deg,
    ${flash[$type].background} 0%,
    ${flash[$type].background} 60%,
    ${transparentize(1, flash[$type].background)} 100%)
  `}
`;

export const ReadMoreButton = styled.button`
  position: absolute;
  bottom: 0;
  text-decoration: underline;
  background: rgba(255, 255, 255, 0);
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

export const InnerContent = styled.div.attrs(({ $type }: FlashProps) => ({
  "data-testid": `flash-${$type}-inner-content`,
}))`
  max-height: 4.5rem;
  overflow: display;
  display: flex;
  flex-direction: column;
`;

export const FlashIcon = styled(Icon).attrs(({ $type }: FlashProps) => ({
  "aria-hidden": "true",
  "data-testid": `flash-${$type}-icon`,
}))<FlashProps>`
  ${({ $type }) => `color: ${flash[$type].icon};`}
`;
